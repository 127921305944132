import { signTransactions, readGlobalState, readLocalState, buildState } from './utils'
import { AlgoClient } from '@/algo-client'
import { STAKING_CALL_APP, STAKING_GLOBAL, STAKING_LOCAL } from './constants'
import { Zero } from '@/constants'
import { FixedNumber } from '@ethersproject/bignumber'
import { get } from 'lodash'
import { toUtf8Bytes } from 'ethers/lib/utils'
import { ethers } from 'ethers'
import { bigNumberHelper, bnHelper } from '@/helper/bignumber-helper'
import algosdk from 'algosdk'
import { walletStore } from '@/stores/wallet-store'
import moment from 'moment'
import { promiseHelper } from '@/helper/promise-helper'
import { snackController } from '@/components/snack-bar/snack-bar-controller'

export class StakingHandler {
  appID = 0
  private _appAccount
  amount = Zero
  asaId = 0
  maxTier = 0
  participants = 0
  tierConfigs = []
  globalState?: any
  constructor() {
    this.appID = +`${process.env.VUE_APP_STAKING_APP_ID}`
    this._appAccount = process.env.VUE_APP_STAKING_APP_ACCOUNT
  }

  async getGlobalState() {
    this.globalState = await readGlobalState(AlgoClient, this.appID)
    return buildState(this.globalState, STAKING_GLOBAL)
  }
  async getLocalState(account) {
    const res = await readLocalState(AlgoClient, account, this.appID)
    return buildState(res, STAKING_LOCAL)
  }

  async loadData() {
    try {
      const { amount, asaId, maxTier, participants } = await this.getGlobalState()
      this.amount = bnHelper.fromDecimals(get(amount, 'value.uint', '0'))
      this.asaId = get(asaId, 'value.uint', '0')
      this.maxTier = get(maxTier, 'value.uint', '0')
      this.participants = get(participants, 'value.uint', '0')
      // await this.fetchTierConfig()
    } catch (e: any) {
      snackController.error(e.message || e.msg || e)
    }
  }

  //get user info
  async getUserASABalance(account: string) {
    const accountInfo = (await AlgoClient.accountInformation(account).do()) as any
    if (this.asaId && accountInfo.assets) {
      const tokenASAInfo = accountInfo.assets.filter((item) => item['asset-id'] === this.asaId)[0]
      if (tokenASAInfo?.amount) {
        return bigNumberHelper.fromDecimals(tokenASAInfo.amount)
      }
    }
    return Zero
  }

  async getUserInfo(account: string) {
    const res = await this.getLocalState(account)
    if (!res) return res
    const amount = get(res, 'amount.value.uint', '0')
    const tier = get(res, 'tier.value.uint', 0)
    const stakeTime = get(res, 'stakeTime.value.uint', 0)
    return { amount: bnHelper.fromDecimals(amount), tier, stakeTime }
  }

  //get tier config
  getTierAmountKey(tier) {
    const o = toUtf8Bytes('a')
    const paddedId = ethers.utils.zeroPad(ethers.utils.hexlify(tier), 8)
    const keys = new Uint8Array([...paddedId, ...o])
    return keys
  }

  getTierPeriodKey(tier) {
    const o = toUtf8Bytes('p')
    const paddedId = ethers.utils.zeroPad(ethers.utils.hexlify(tier), 8)
    const keys = new Uint8Array([...paddedId, ...o])
    return keys
  }

  async fetchTierConfig() {
    const tierAmountKeys = {}
    const tierPeriodKeys = {}
    for (let i = 1; i <= this.maxTier; i++) {
      tierAmountKeys[i] = this.getTierAmountKey(i)
      tierPeriodKeys[i] = this.getTierPeriodKey(i)
    }
    const tierAmounts = buildState(this.globalState, tierAmountKeys)
    const tierPeriods = buildState(this.globalState, tierPeriodKeys)
    const tierConfigs: any = []
    Object.values(tierAmounts).map((item, index) => {
      tierConfigs[index] = { amount: bnHelper.fromDecimals(get(item, 'value.uint', '0')) }
      return
    })
    Object.values(tierPeriods).map((item, index) => {
      tierConfigs[index] = { ...tierConfigs[index], period: get(item, 'value.uint', '0') }
      return
    })
    this.tierConfigs = tierConfigs || []
  }

  //main function
  async sendOptIn(walletAddress: string) {
    const params = await AlgoClient.getTransactionParams().do()
    if (params && this.appID) {
      const txn = algosdk.makeApplicationOptInTxn(walletAddress, params, this.appID)
      const signedTxn = await signTransactions(walletStore.selectedWallet, txn)
      const { txId } = await AlgoClient.sendRawTransaction(signedTxn).do()
      const res = await algosdk.waitForConfirmation(AlgoClient, txId, 3)
    }
  }

  async stake(account: string, tier: number, amount: FixedNumber) {
    const params = await walletStore?.algodClient?.getTransactionParams().do()
    if (params && this.appID && this._appAccount && this.asaId) {
      const txn1 = algosdk.makeApplicationNoOpTxnFromObject({
        suggestedParams: { ...params },
        from: account,
        appIndex: this.appID,
        appArgs: [new Uint8Array(Buffer.from(STAKING_CALL_APP.Stake)), algosdk.encodeUint64(tier)],
      })

      const txn2 = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        from: account,
        to: this._appAccount,
        assetIndex: this.asaId,
        amount: bigNumberHelper.toDecimalBigNumber(amount),
        suggestedParams: { ...params },
      })
      const signedTxns = await signTransactions(walletStore.selectedWallet, [txn1, txn2])
      const { txId } = await AlgoClient.sendRawTransaction(signedTxns).do()
      await algosdk.waitForConfirmation(AlgoClient, txId, 3)
    }
  }

  async unstake(account: string, tier: number, amount: FixedNumber) {
    const params = await walletStore?.algodClient?.getTransactionParams().do()
    if (params && this.appID && this._appAccount && this.asaId) {
      const txn = algosdk.makeApplicationNoOpTxnFromObject({
        suggestedParams: { ...params, fee: 2000, flatFee: true },
        from: account,
        appIndex: this.appID,
        appArgs: [
          new Uint8Array(Buffer.from(STAKING_CALL_APP.Unstake)),
          algosdk.encodeUint64(bigNumberHelper.toDecimalBigNumber(amount)),
          algosdk.encodeUint64(tier),
        ],
        foreignAssets: [this.asaId],
      })
      const signedTxns = await signTransactions(walletStore.selectedWallet, [txn])
      const { txId } = await AlgoClient.sendRawTransaction(signedTxns).do()
      await algosdk.waitForConfirmation(AlgoClient, txId, 3)
    }
  }
}
